<template>
  <div class="page-wrapper">
    <div v-if="loading.verify" v-loading="loading.verify" />

    <template v-if="!loading.verify">
      <h1 class="title">{{ displayData.pageTitle }}</h1>

      <div>
        <BaseElForm ref="formRef" :model="formData" :rules="formRules">
          <BaseElFormItem v-if="showControl.formItem.email" :label="displayText.formItemLabel.email">
            <BaseElInput :value="originEamil" readonly disabled />
          </BaseElFormItem>
          <BaseElFormItem :label="displayText.formItemLabel.newPassword" prop="newPassword">
            <div>
              <BaseElInput v-model="formData.newPassword" type="password" show-password />
              <p class="text-sm text-gray-60">{{ displayText.hint.passwordRules }}</p>
            </div>
          </BaseElFormItem>
          <BaseElFormItem :label="displayText.formItemLabel.confirmPassword" prop="confirmPassword">
            <BaseElInput v-model="formData.confirmPassword" type="password" show-password />
          </BaseElFormItem>
        </BaseElForm>

        <div class="flex justify-end w-full">
          <BaseElButton :loading="loading.submit" type="primary" @click="onSubmit">
            {{ displayData.btn.submit }}
          </BaseElButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { useBaseForm } from '@/use/useForm'
import { atLeastOneLowerCaseRules, atLeastOneNumberRules, atLeastOneSpecialCharRules, atLeastOneUpperCaseRules, emailRules, minLengthRules, noEmptyRules } from '@/validation'
import { get } from 'lodash'
import { computed, defineComponent, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { GuestResetPassword } from '@/api/auth'
import { UpdateMePassword } from '@/api/user'
import store from '@/store'
import { useSessionStorage } from '@vueuse/core'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'SetPassword',
  setup (props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    const state = useSessionStorage('forgetPasswordState', { code: null, forgetPasswordAuthnId: null })
    const loading = reactive({
      submit: false,
      verify: false,
    })
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    initFormData({
      newPassword: null,
      confirmPassword: null,
    })
    const formRules = computed(() => {
      const rules = {
        newPassword: [
          noEmptyRules(),
          minLengthRules(8),
          atLeastOneUpperCaseRules(),
          atLeastOneLowerCaseRules(),
          atLeastOneNumberRules(),
          atLeastOneSpecialCharRules(),
        ],
        confirmPassword: [
          noEmptyRules(),
          // 驗證是否與 formData.newPassword 相同
          {
            validator: (rule, value, callback) => {
              if (value !== formData.newPassword) {
                return callback(new Error(i18n.t('validation.passwordRules.passwordNotMatch.text')))
              }
              return callback()
            },
            trigger: 'blur',
          },
        ],
      }
      return rules
    })

    const isTempRest = computed(() => get(route.query, 'tempPedRest') === 'true')
    const originEamil = computed(() => get(store.state, 'user.user.AuthEmail.email'))
    const orgId = computed(() => get(store.state, 'org.org.id'))

    const displayData = computed(() => {
      const data = {
        pageTitle: i18n.t('page.changePassword.title'),
        btn: {
          submit: i18n.t('common.button.submit.text'),
        },
      }

      if (isTempRest.value) {
        data.pageTitle = i18n.t('page.setPassword.title')
        data.btn.submit = i18n.t('auth.button.submitAndLogin.text')
      }

      return data
    })

    const displayText = computed(() => {
      const text = {
        formItemLabel: {
          email: i18n.t('auth.form.email.label'),
          newPassword: i18n.t('auth.form.newPassword.label'),
          confirmPassword: i18n.t('auth.form.confirmPassword.label'),
        },
        hint: {
          passwordRules: i18n.t('auth.form.passwordRules.hint'),
        },
      }
      return text
    })

    const showControl = computed(() => {
      const controls = {
        formItem: {
          email: false,
        },
      }

      if (isTempRest.value) {
        controls.formItem.email = true
      }

      return controls
    })

    const onSubmit = async () => {
      if (loading.submit) return
      loading.submit = true
      const isValid = await checkForm(formRef.value)
      if (!isValid) {
        loading.submit = false
        return
      }

      let apiMethod
      if (isTempRest.value) {
        apiMethod = UpdateMePassword
      } else {
        apiMethod = GuestResetPassword
      }

      const payload = {
        orgId: orgId.value,
        forgetPasswordAuthnId: state.value.forgetPasswordAuthnId,
        code: state.value.code,
        password: formData.newPassword,
      }

      const [res, err] = await apiMethod(payload)
      if (err) {
        window.$message.error(err)
        return
      }

      if (isTempRest.value) {
        const { features, shopPlan } = await store.dispatch('user/getMe')
        const accessRoutes = await store.dispatch('permission/generateRoutes', { features, shopPlan })
        accessRoutes.map(item => router.addRoute(item))
        window.$message.success(i18n.t('auth.toast.passwordUpdated.text'))
      } else {
        window.$message.success(i18n.t('auth.toast.passwordUpdatedAndPlsRelogin.text'))
      }

      sessionStorage.removeItem('forgetPasswordState')
      loading.submit = false

      setTimeout(() => {
        router.push({
          name: 'Login',
        })
      }, 1000)
    }

    return {
      displayData,
      displayText,
      formRules,
      formRef,
      formData,
      loading,
      onSubmit,
      originEamil,
      showControl,
      isTempRest,
    }
  },
})
</script>

<style lang="postcss" scoped>
.page-wrapper {
  @apply flex flex-col items-center justify-center h-full;
}
.title {
  @apply text-[32px] font-medium text-center;
}
</style>
